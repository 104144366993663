<template>
    <div>
        <div class="col-md-12">
            <h3 class="page-header">
                <i class="fa fa fa-dashboard animated bounceInDown show-info"></i>
                OUTSTANDINGS & REPORTS
            </h3>
        </div>
        <div class="info-box bg-warning text-white">
            <div class="info-icon bg-primary-dark" style="padding-bottom:6px;">
                <h4>
                    <i class="fa fa fa-check-square-o "></i>
                    Yesterday
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-info">
                    <div class="inner">
                        <h3>{{ this.customerscount }}</h3>
                        <p>Total Customers</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-bag"></i>
                    </div>
                    <router-link to="customer" class="small-box-footer">More info <i class="fa fa fa-arrow-circle-right animated bounceInDown"></i></router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>53</h3>
                        <p>Total Active Accounts</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{ this.defaultcustomerscount }}</h3>
                        <p>Total Defaulters</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>{{ this.advisorscount }}</h3>
                        <p>Total Advisors</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-purple">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Paid Emi</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-Cyan">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Outstanding</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->
        <div class="info-box bg-warning text-white">
            <div class="info-icon bg-primary-dark" style="padding-bottom:6px;">
                <h4>
                    <i class="fa fa fa-check-square-o "></i>
                    This Week
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-info">
                    <div class="inner">
                        <h3>150</h3>
                        <p>Total Customers</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-bag"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i
                            class="fa fa fa-arrow-circle-right animated bounceInDown"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>53</h3>
                        <p>Total Active Accounts</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>44</h3>
                        <p>Total Defaulters</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Advisors</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-purple">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Paid Emi</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-Cyan">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Outstanding</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->
        <div class="info-box bg-warning text-white">
            <div class="info-icon bg-primary-dark" style="padding-bottom:6px;">
                <h4>
                    <i class="fa fa fa-check-square-o "></i>
                    This Month
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-info">
                    <div class="inner">
                        <h3>150</h3>
                        <p>Total Customers</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-bag"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i
                            class="fa fa fa-arrow-circle-right animated bounceInDown"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>53</h3>
                        <p>Total Active Accounts</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>44</h3>
                        <p>Total Defaulters</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Advisors</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-purple">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Paid Emi</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-Cyan">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Outstanding</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->
        <div class="info-box bg-warning text-white">
            <div class="info-icon bg-primary-dark" style="padding-bottom:6px;">
                <h4>
                    <i class="fa fa fa-check-square-o "></i>
                    Over All
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-info">
                    <div class="inner">
                        <h3>{{ this.customerscount }}</h3>
                        <p>Total Customers</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-bag"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i
                            class="fa fa fa-arrow-circle-right animated bounceInDown"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>53</h3>
                        <p>Total Active Accounts</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-stats-bars"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div class="inner">
                        <h3>{{ this.defaultcustomerscount }}</h3>
                        <p>Total Defaulters</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->
        <div class="row">
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>{{ this.advisorscount }}</h3>
                        <p>Total Advisors</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-purple">
                    <div class="inner">
                        <h3>65</h3>
                        <p>Total Paid Emi</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-4">
                <!-- small box -->
                <div class="small-box grd-bg-Cyan">
                    <div class="inner">
                        <h3>{{ this.sumofremainingamount }}</h3>
                        <p>Total Outstanding</p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a href="#" class="small-box-footer">More info <i class="fa fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div><!-- ./col -->


    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'dashboard',
    computed: {
        ...mapGetters(['customerscount', 'employeescount', 'partiescount', 'defaultcustomerscount',
            'advisorscount', 'sumofremainingamount'])

    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.$store.dispatch("fetchcustomerscount");
            this.$store.dispatch("fetchdefaultcustomerscount");
            this.$store.dispatch("fetchadvisorscount");
            this.$store.dispatch("fetchsumofremainingamount");
        }
    }
}
</script>